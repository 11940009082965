body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* copied styles for menu */
.title-seo {
    font-size: 0;
    line-height: 0;
}
.header {
    background-color: #dadce5;
}
.header__top {
    padding: 0 128px;
}
.header__logo {
    width: 115px;
}
.header__nav {
    margin-top: 10px;
    width: calc(100% - 200px);
    max-width: 940px;
}
.icon-nav-mobile {
    position: relative;
    width: 35px;
    z-index: 112;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 35px;
    overflow: hidden;
}
.headerMenu {
    width: 100%;
}
ol, ul {
    list-style: none;
}
.headerMenu__item a {
    letter-spacing: 2.75px;
    color: #6a728b;
    text-transform: uppercase;
}

.react-date-picker.react-date-picker--open.react-date-picker--enabled{
    z-index: 12;
}

.header__top a.js-anchor{
    text-decoration: none;
}

.admin_header{
    background-color: white;
}

.admin_content{
    /* padding: 20px; */
    background: linear-gradient(to bottom right, rgb(87, 121, 157), rgb(172, 177, 192));
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
}

.js-anchor:hover{
    cursor: pointer;
}

.card_wrapper{
    /* max-width: 2000px; */
    aspect-ratio: 20/9;
    overflow: hidden;
    /* overflow-x: hidden; */
    /* overflow-y: scroll; */
    width: 100%;
    background-color: rgb(78, 94, 133);
    /* border-radius: 10px; */
    /* margin-top: 30px; */
    box-shadow: rgb(0 0 0 / 45%) 0px 25px 20px -20px;
    align-self: center;
    display: flex;
    -ms-overflow-style: none;
    scrollbar-width: none;
    min-height: calc(100vh - 94px);
}

#wrapper{
    /* max-height: 100vh;
    min-height: 1000px;
    overflow: hidden; */
}

.react-daterange-picker {
  background: white;
}

.main_date{
    color: white;
    font-weight: bold;
    font-size: 25px !important;
}

.card_sidenav{
    width: 4%;
    /* min-height: 50vh; */
    background-color: rgb(66, 80, 120);
    border-radius: 10px 0px 0px 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    padding-top: 30px;
}

.card_sidenav li{
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 15px 0px 15px 0px;
}

li.headerMenu__item.selected {
    background: rgb(84, 105, 163);
    border-left: rgb(124,145,202) solid;
}

.table td, th{
    color: white;
    border: white solid 1px;
}

.card_content h1 {
    color: white;
    font-weight: bold;
    font-size: xx-large;
}

.card_content{
    width: 96%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 50px;

}
.card_content::-webkit-scrollbar {
    display: none;
  }


.container{
    margin: 30px;
    width: calc(100% - 60px);
}

.permit_letter{
    width: 15px;
  height: 15px;
  line-height: 15px;
  border-radius: 50%;
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  text-align: center;
}

.user_db_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    padding: 25px 50px 25px 50px;
}

.edit_user:hover{
    cursor: pointer;
}

thead tr th {
    border: none;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}

thead tr th div {
    background-color: black !important;
    border: white solid 1px;
    color: darkgray;
    font-size: 15px !important;
    font-weight: 500;
    padding: 0px 10px;
    /* margin-bottom: 10px !important; */
}

header.user_container{
    display: flex;
    margin-top: 20px;
    margin-left: 10px;
    align-items: center;
}

.back_button{
    background: rgb(160, 181,224);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    margin-right: 5px;

}

.back_button:hover{
    cursor: pointer;
}

.row h3{
    font-size: 20px;
    font-weight: bold;
    color: white;
}

.user_db_title{
    height: 50px;
    align-self: flex-start;
}

.basic_info_container{
    margin-top: 5px;
    border-top: rgb(51, 64, 92) solid;
    border-left: rgb(51, 64, 92) solid;

    border-radius: 10px 0px 0px ;
}


.basic_info_container .col{
    border-right: rgb(51, 64, 92) solid;

}

.basic_info_name{
    color: rgb(152, 181, 242) !important;
    font-weight: bold;
}

.profile_placeholder{
    background-color: rgb(51, 64, 93);
    width: fit-content;
    border-radius: 5px;
}

.row>* {
    padding-right: 5px;
    padding-top: 5px;
    padding-left: 5px;
}

.page-link{
    color: white;
    background-color: transparent;
    border: 2px solid rgb(51,64,93);
    padding: 2px 7px;
    font-size: 15px;
    font-weight: 400;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: rgb(107, 129, 181);
    border: 2px solid rgb(51,64,93);

}

.page-link:hover {
    z-index: 2;
    color: rgb(51,64,93);
    background-color: #e9ecef;
    border: 2px solid rgb(51,64,93);

}

.basic_info_item_box{
    border: white solid 2px;
    border-radius: 5px;
    display: flex;
    width: 90%;
    padding: 2px;
    justify-content: space-between;
    margin-bottom: 15px;
}

.white_text{
    color:white;
}

.black_text{
    color:black;
}
.basic_info_item_box p{
    font-weight: 500;
    font-size: 14px;
}

.last_login_container{
    display: flex;
    width: 90%;
}

.last_login_container .white_text, .last_login_container .black_text{
    font-weight: 600;
    font-size: 14px;
}

.change_button{
    color: white;
    background-color: rgb(126, 149, 209);
    padding: 2px 12px;
    border-radius: 20px;
    font-size: 15px;
    font-weight: 600;
    margin-top: 20px;
}

.change_button:hover{
    cursor: pointer;
}

.bold_text{
    font-weight: bold;
}

.stat_item{
    display: flex;
    margin: 15px 0px;
}

.pagination{
    /* margin-bottom: 20px; */
}




table.user-list-table {
    width: 100%;
    table-layout: fixed;
}
.user-list-table td{
    border: 2px solid rgb(123, 147, 226);
    overflow: hidden;
    white-space: nowrap;
    color: white;
}
.short-desc{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 90%;
    padding: 5px;
}

.top-th{
    border: none;
    font-size: 18px;
    padding: 3px;
    /* display: flex;
    justify-content: center; */
}
.top-th:hover{
  color: #eee;
  cursor: pointer;
}

.top-th div{
    display: flex;
    justify-content: center;

    font-weight: 700;
}

.bottom-th{
    border: none;

}

.bottom-th div{
    background-color: black;
    border: 2px solid white;
    font-size: 15px;
    color: grey;
    margin: 10px;
    display: flex;
    justify-content: center;
}

.th-hidden{
    border: none;
    background-color: transparent;
}



.small_widget_scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.small_widget_scroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  /* width: 100%;
  height: 20%;
  display: flex;
  overflow: scroll; */
}
